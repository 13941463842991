var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "alert",
          expression: "widget.compType === 'alert'",
        },
      ],
    },
    [
      [
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_name") } },
          [
            _c("lang-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compName,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compName", $$v)
                },
                expression: "widget.compName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_code") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compCode,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compCode", $$v)
                },
                expression: "widget.compCode",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_type") } },
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: _vm.$t("lang_please_select"),
                  clearable: "",
                },
                model: {
                  value: _vm.widget.properties.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "type", $$v)
                  },
                  expression: "widget.properties.type",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: "success", value: "success" },
                }),
                _c("el-option", {
                  attrs: { label: "warning", value: "warning" },
                }),
                _c("el-option", { attrs: { label: "error", value: "error" } }),
                _c("el-option", { attrs: { label: "info", value: "info" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_auxiliary_text") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.properties.description,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "description", $$v)
                },
                expression: "widget.properties.description",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_center") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.center,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "center", $$v)
                },
                expression: "widget.properties.center",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_shut_down") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.closable,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "closable", $$v)
                },
                expression: "widget.properties.closable",
              },
            }),
          ],
          1
        ),
        _vm.widget.properties.closable
          ? _c(
              "el-form-item",
              { attrs: { label: _vm.$t("lang_close_button_custom_text") } },
              [
                _c("el-input", {
                  staticClass: "input",
                  attrs: {
                    placeholder: _vm.$t("lang_please_enter"),
                    clearable: "",
                  },
                  model: {
                    value: _vm.widget.properties.closeText,
                    callback: function ($$v) {
                      _vm.$set(_vm.widget.properties, "closeText", $$v)
                    },
                    expression: "widget.properties.closeText",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_display_icon") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.showIcon,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "showIcon", $$v)
                },
                expression: "widget.properties.showIcon",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_theme") } },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.widget.properties.effect,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "effect", $$v)
                  },
                  expression: "widget.properties.effect",
                },
              },
              [
                _c("el-radio", { attrs: { label: "light" } }, [
                  _vm._v(_vm._s(_vm.$t("lang_bright_color"))),
                ]),
                _c("el-radio", { attrs: { label: "dark" } }, [
                  _vm._v(_vm._s(_vm.$t("lang_black"))),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_operation_status") } },
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: _vm.$t("lang_please_select"),
                  clearable: "",
                },
                model: {
                  value: _vm.widget.properties.operation,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "operation", $$v)
                  },
                  expression: "widget.properties.operation",
                },
              },
              _vm._l(_vm.statusList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.label, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }